<template>
  <div class="offerCard">
    <div class="offerCard__image">
        <img :src="image">
    </div>
    <div class="offerCard__description">
      <p class="lightgray-text">{{location}}</p>
      <h3>{{title}}</h3>
      <h2>{{price}}</h2>
    </div>
  </div>
</template>

<script>
export default {
  name: 'OfferCard',
  props: {
    location: String,
    title: String,
    place: String,
    price: String,
    image: String
  },
  data: function () {
    return {

    }
  }
}
</script>

<style scoped lang="stylus">
.offerCard
  display inline-block
  height 300px
  text-align center
  width 160px
  margin 10px
  background white
  cursor pointer
  border-radius 8px
  box-shadow 0 0 10px rgba(0, 0, 0, .1)
  overflow hidden
  transition all 0.4s
  z-index 2
  transition-duration .2s
  &:hover
    box-shadow 0 0 7px rgba(0, 0, 0, .23)
  &__image
    position relative
    width 160px
    height 160px
    background-color #eee
    overflow hidden
    img
      margin 0 auto
      left 0
      position absolute
      height 160px
      z-index 3
  &__loader
    position absolute
    top 0
    left 0
    width 100%
    height 100%
  &__description
    padding 0
    line-height 1em
    .gray-text
      margin-top 0
    .lightgray-text
      font-size 11px
    h3
      margin-bottom 9px
      color #444
      line-height 1.2em
</style>
